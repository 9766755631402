import React, {useState} from "react"
import {Container,Table, Dropdown, Row, Col, Button} from 'react-bootstrap';
import { Link, navigate } from "gatsby";
import LeftNav from "../components/Common/leftNav";
import SEO from "../components/seo"
import PropertyTab from "../components/modules/property-tab"
import PhotoGallery from "../components/modules/photo_gallery"

import mapCardImg from "../assests/images/gallery1.jpg";
import FsLightbox from 'fslightbox-react';


const Seller = (props) => {
  
    let path_name = (props.location.pathname).split('/');
    let get_property_id = path_name[path_name.length-1];

  return(

    <>
      <SEO title="Photo Gallery" description="Photo Gallery" />
      <div className="dashboard-wrapper">
        <LeftNav page_name="Photo Gallery" />
        {/* sidebar */}

        <div className="dashboard-content seller_cnt">
          <Container>
              <div className="content-wrapper ">

                    <PropertyTab tab_name="Photo Gallery" get_property_id={get_property_id} />
                    {/* Tab Content */}

                    <PhotoGallery />
              </div>

          </Container>
          {/* container */}
        </div>
        {/* dashboard-content */}
      </div>
    </>
  )

}

export default Seller